import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`There is a boy named Steve. He is dark skinned. Because of that he gets lots of hate towards him. When he goes to school he gets bullied on the way there and back. He is treated unfairly in school,he wants to be the same as all the rest of the kids but he can’t, none of them want to be friends with him in fact they don’t even want to see him on the school campus because of his skin color.`}</p>
    <p>{`One morning as a normal day for Steve he was walking to school alone. He was almost about to reach school but suddenly  some people creeped up behind him and put a bag on top of his face, tied him together and put him in a car. He didn’t have enough time to look at who it was but from the voice of the people talking it sounded familiar to him. They started shocking him a few times in the car and asking him what he was doing in their country but Steve was too scared to reply. Soon they arrive at a place to which Steve has no idea where it is; they later take his bag off  and Steve understands it is not just some ordinary people it is Steve’s classmate’s father Rick! Steve asks why they brought him here, Rick replied “ we don’t want people like you in our country” in addition Rick tells Steve he will give his parents two choices or they have to leave the country and find a new place to live, or they will make Steve starve to death. If Rick finds out that they involved the police to help find Steve they will kill steve.`}</p>
    <p>{`When Steve’s parents receive the text they are both terrified and they don’t understand what they need to do. They thought that they would need to go back to their home country but it was too far away and too expensive for them so they had  to choose another country somewhere in Europe this time. Both of the parents realize that they will have to sell most of their things to have enough money to travel there and buy somewhere to live. Meanwhile Rick just wrote another letter saying that they only have 24 hours left to decide so they have to hurry up. They decide they will leave that evening and they text that to Rick who they don’t know is Rick. The parents  arrive at the airport first and wait for Steve to get dropped off. Finally Rick arrives, pushes him out of the truck and drives off as fast as possible the parents that steve and go into the airport. The police notice something suspicious and they chase after Rick and ask Steve what happened, he explains the whole story and they eventually catch Rick and put him under bars for kidnapping. In the end Steve and his family were safe and stayed in the same place without going anywhere.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      